import { ITextStyle } from 'pixi.js';
import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const textPositionY = 0;

export const maxLevelTextStyle = new PIXI.TextStyle({
  fontFamily: font,
  fill: ['#ff0000', '#800000'],
  fontSize: 48,
  stroke: '#FFFF80',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowAlpha: 1,
  dropShadowBlur: 0,
  dropShadowColor: '#000000',
  dropShadowDistance: 4,
  fontStyle: 'italic',
  fontWeight: 'bold',
  letterSpacing: 0,
  lineJoin: 'round',
});

const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
  miterLimit: 1,
};

const GRADIENT_GOLD_TITLE_COLOR_CONFIG: Partial<ITextStyle> = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  lineJoin: 'round',
  letterSpacing: 0,
};

const GRADIENT_BLUE_TITLE_COLOR_CONFIG = {
  fill: ['#9ff0ff', '#ffffff', '#9ff0ff', '#8fbfff', '#808f9f'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111166',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

const GRADIENT_DISABLE_TITLE_COLOR_CONFIG = {
  fill: ['#101010', '#383838', '#727272', '#383838', '#101010'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#000000',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

export const buyFeatureTitleStyle: Partial<ITextStyle> = {
  fontSize: 70,
  fontFamily: font,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
};

export const buyFeatureConfirmStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  fill: '#ff3333',
  stroke: '#661111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
};

export const totalCostTextStyle: Partial<ITextStyle> = {
  fontSize: 50,
  fontFamily: font,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 4,
};

export const totalCostTextAmountStyle: Partial<ITextStyle> = {
  fontSize: 100,
  fontFamily: font,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
};

export const amountTextStyle: Partial<ITextStyle> = {
  fontSize: 40,
  fontFamily: font,
  lineHeight: 40,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
  lineJoin: 'round',
  wordWrap: true,
  wordWrapWidth: 440,
  breakWords: true,
};

export const betValueStyle: Partial<ITextStyle> = {
  fontSize: 80,
  fontFamily: font,
  lineHeight: 50,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 0,
  miterLimit: 1,
};

export const betValueStyleConfirm: Partial<ITextStyle> = {
  fontSize: 90,
  fontFamily: font,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 7,
};

export const buyFeatureBtnTitleStyle: Partial<ITextStyle> = {
  fontSize: 30,
  fontFamily: font,
  ...GRADIENT_BLUE_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
  align: 'center',
};

export const buyFeatureMaxLevelTitleStyle: Partial<ITextStyle> = {
  ...maxLevelTextStyle,
  fontSize: 40,
};

export const buyFeatureBtnTitleDisableStyle: Partial<ITextStyle> = {
  fontSize: 30,
  fontFamily: font,
  ...GRADIENT_DISABLE_TITLE_COLOR_CONFIG,
  strokeThickness: 2,
  align: 'center',
};

export const buyFeatureMaxLevelTitleDisableStyle: Partial<ITextStyle> = {
  ...buyFeatureMaxLevelTitleStyle,
  ...GRADIENT_DISABLE_TITLE_COLOR_CONFIG,
};

export const totalCostTextAmountDisableStyle = {
  fontSize: 50,
  fontFamily: font,
  lineHeight: 50,
  ...GRADIENT_DISABLE_TITLE_COLOR_CONFIG,
  strokeThickness: 5,
};
