import * as PIXI from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';
import { spinsStyle } from '../gameView/config';

import { FREE_SPIN_ANIMATION_DELAY, FREE_SPIN_ANIMATION_LOOP, FREE_SPIN_ANIMATION_SCALE } from './config';

export class FreeSpinsCounter extends ViewContainer {
  private textContainer: ViewContainer;

  private freeSpinsText: PIXI.Text;

  private spinsNumText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spinsAmount: number;

  private currentSpin: number;

  constructor(props: FreeSpinsTitleProps) {
    super();

    this.textContainer = new ViewContainer();
    this.freeSpinsText = this.initFreeSpinText();
    this.spinsNumText = this.initSpinsText(props.spins, props.currentSpin);

    this.spinsAmount = props.spins;
    this.currentSpin = props.currentSpin;

    this.init();

    eventManager.addListener(
      EventTypes.UPDATE_FREE_SPINS_COUNT,
      (spins: number, curr: number, immediately: boolean): void => this.handleUpdate(spins, curr, immediately),
    );

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      (spins: number, curr: number, immediately?: boolean): void => this.handleUpdate(spins, curr, immediately!),
    );
  }

  private init(): void {
    this.textContainer.name = 'text';
    this.textContainer.addChild(this.freeSpinsText);
    this.textContainer.addChild(this.spinsNumText);
    this.textContainer.position.set(-457, 35);
    this.textContainer.pivot.x = -(927 - this.textContainer.width - 20) / 2;
    this.addChild(this.textContainer);
  }

  private initFreeSpinText(): PIXI.Text {
    const freeSpinTitle = new PIXI.Text(i18n.t('freeSpinsTitleText'), spinsStyle);
    freeSpinTitle.resolution = 1;
    freeSpinTitle.anchor.set(0, 0.5);
    freeSpinTitle.scale.set(freeSpinTitle.scale.x, freeSpinTitle.scale.y);
    updateTextScale(freeSpinTitle, 590, 500);

    return freeSpinTitle;
  }

  private initSpinsText(spins: number, currentSpin: number): PIXI.Text {
    const freeSpinTitle = new PIXI.Text(this.formatSpins(spins, currentSpin), spinsStyle);
    freeSpinTitle.resolution = 1;
    freeSpinTitle.anchor.set(0, 0.5);
    freeSpinTitle.x = this.freeSpinsText.width + 15;
    freeSpinTitle.scale.set(this.freeSpinsText.scale.x, this.freeSpinsText.scale.y);

    return freeSpinTitle;
  }

  private handleUpdate(spins: number, currentSpin: number, _immediately: boolean): void {
    if (this.spinsNumText.text === this.formatSpins(spins, currentSpin)) return;
    this.spinsNumText.text = this.formatSpins(spins, currentSpin);

    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsNumText,
      this.spinsNumText.scale.x * FREE_SPIN_ANIMATION_SCALE,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
      this.spinsNumText.scale.x,
      this.spinsNumText.scale.x,
    );
    this.pulsAnimation?.start();
  }

  private formatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin !== undefined ? currentSpin : this.currentSpin} / ${
      spins !== undefined ? spins : this.spinsAmount
    }`;
  }

  public override destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.UPDATE_FREE_SPINS_COUNT);
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
  }
}
export default FreeSpinsCounter;
