import { GameMode, IAuthInput } from '../../global.d';
import {
  setBetAmount,
  setBrokenBuyFeature,
  setBrokenGambleGame,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setReelSetId,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { getGameSettingsGql } from '../../gql/query';
import { IGameSettings } from '../../slotMachine/d';
import { findSubstituteCoinAmount, parseQuery } from '../../utils';

export const useGameSettingsData = () => {
  const getGameSettingsData = async () => {
    const { slotId, clientId } = parseQuery<Omit<IAuthInput, 'lng'>>();
    const {
      data: { gameSettings },
    } = await client.query<{ gameSettings: IGameSettings }>({
      query: getGameSettingsGql,
      variables: { input: { slotId, clientId } },
      fetchPolicy: 'network-only',
    });
    setSlotConfig({
      ...setSlotConfig(),
      clientSlotSettings: gameSettings.clientSlotSettings,
      slotSettings: gameSettings.slotSettings,
      slotId: gameSettings.slotId,
    });
    setGameMode(GameMode.BASE_GAME);
    setReelSetId(GameMode.BASE_GAME);
    let coinValue;
    let coinAmount;

    if (setBrokenGame() || setBrokenBuyFeature()) {
      const currentBonus = setCurrentBonus();
      coinValue = currentBonus.coinValue;
      coinAmount = currentBonus.coinAmount;
    } else if (setBrokenGambleGame()) {
      coinValue = setUserLastBetResult().coinValue;
      coinAmount = setUserLastBetResult().coinAmount;
    } else {
      const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
      coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, gameSettings.clientSlotSettings.betCoinAmountSettings);
      coinValue = gameSettings.clientSlotSettings.coinValueSettings.find((value) => value.code === setCurrency())
        ?.variants[0];
    }
    setCoinValue(coinValue);
    setCoinAmount(coinAmount);
    setWinAmount(setUserLastBetResult().betStorage.estimatedWinCoinAmount);
    setBetAmount(coinAmount * gameSettings.slotSettings.globalCoinAmountMultiplier);
  };
  return { getGameSettingsData };
};

// const getSlotData = async () => {
//   const slotData = await client.query<{ slot: ISlotData }>({
//     query: getSlotGql,
//     variables: { input: { id: setSlotConfig().id } },
//     fetchPolicy: 'network-only',
//   });
//   const { slot } = slotData.data;

//   setSlotConfig({
//     ...setSlotConfig(),
//     clientSettings: slot.clientSettings,
//     icons: slot.icons,
//     reels: slot.reels,
//     winLines: slot.lines,
//     lines: slot.lines,
//     lineSet: slot.lineSets[0]!,
//     settings: slot.settings,
//   });
//   console.log('setSlotConfig.lineSet', setSlotConfig().lineSet.id);
//   // todo add logic to pick gamemode and reelsetid
//   setReelSetId(slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR]);
//   const coinValue = setUserLastBetResult().id
//     ? setUserLastBetResult().coinValue
//     : slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
//   setCoinValue(coinValue);
//   const coinAmount = setUserLastBetResult().id
//     ? setUserLastBetResult().coinAmount
//     : slot.clientSettings.coinAmounts.default[0];
//   setCoinAmount(coinAmount);
//   setWinAmount(setUserLastBetResult().result.winCoinAmount);
//   setBetAmount(setCoinAmount() * slot.lineSets[0]!.coinAmountMultiplier);
// };
