/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { config, mappedAudioSprites } from '../../config';
import { ISongs } from '../../config/audio/sprite.generated';
import { EventTypes, GameMode, GraphQLErrorsType, IBalance, ISettledBet } from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBalanceAmount,
  setBrokenBuyFeature,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCollectPush,
  setCurrency,
  setCurrentBonus,
  setGameHistory,
  setGameMode,
  setIsAfterSpin,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsCollectSpin,
  setIsCollected,
  setIsContinueAutoSpinsAfterFeature,
  setIsCountUp,
  setIsFadeOut,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenAutoplayPopup,
  setIsOpenBetSettingsPopup,
  setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsOpenedMessageBanner,
  setIsPhoenix,
  setIsReplay,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setNextResult,
  setSlotConfig,
  setSpinWithAutoSpin,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setUserBalance,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import { IConfig, IProgress, ISlotConfig } from '../../gql/d';
import { placeBetGql } from '../../gql/mutation';
import {
  configGql,
  getAutoSpinsGql,
  getBalanceGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  isStoppedGql,
} from '../../gql/query';
import Tween from '../../slotMachine/animations/tween';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { SlotMachineState, eventManager } from '../../slotMachine/config/index';
import SlotMachine from '../../slotMachine/index';
import { showCurrency } from '../../utils';
import { canPressSpin, isBaseGameMode, isFreeSpinGameMode, saveReelPosition } from '../../utils/helper';

import { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { slotId } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userBalance } = useQuery<{ balance: IBalance }>(getBalanceGql);
  const { data: dataProgress } = useQuery<{ progress: IProgress }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean; isSlotStatueIdle: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userBalance!.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const { isSlotStopped } = data!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinGameMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().roundsPlayed;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
      if (error.graphQLErrors.some((err) => err.extensions?.['type'] === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    },

    async onCompleted({ placeBet }) {
      eventManager.emit(EventTypes.PLACE_BET_COMPLETED);
      client.writeQuery({
        query: getBalanceGql,
        data: {
          ...userBalance,
          balance: placeBet.balance.placed,
        },
      });
      //      console.log('===placeBet', placeBet);
      if (placeBet.bet.outcomes[0]!.stateSnapshot.reelPosition.length === 0 && !isFreeSpinGameMode(setGameMode())) {
        return;
      }

      if (placeBet.bet.outcomes[0]!.stateSnapshot.reelPosition.length === 0 && isFreeSpinGameMode(setGameMode())) {
        const spinState = SlotMachine.getInstance().state;
        if (spinState === SlotMachineState.SPIN && isSlotStopped) {
          eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, true);
        }
      }

      SlotMachine.getInstance().setResult(placeBet);
      setUserLastBetResult({ ...setUserLastBetResult(), coinValue: placeBet.bet.coinValue });

      if (SlotMachine.getInstance().isStopped) {
        const spinState = SlotMachine.getInstance().state;
        if (spinState != SlotMachineState.IDLE) {
          SlotMachine.getInstance().spin(isTurboSpin);
        }
      }
      const callBack = () => {
        const win = placeBet.bet.betStorage.estimatedWinCoinAmount;
        const lastThreeSpins = [...setGameHistory().slice(1), !!win];
        const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
        setGameHistory(lastThreeSpins);
        const betResult = setNextResult()!;
        setUserBalance(betResult.balance.settled);

        // フリースピン中と上限だったは、falseにしない
        if (!isFreeSpinGameMode(setGameMode()) && win > 0 && placeBet.winCoinAmount === 0) {
          setIsCollected(false);
        }

        if (
          placeBet.bet.coinAmount * setSlotConfig().slotSettings.globalCoinAmountMultiplier * 5 <= win &&
          !thirdWinInRow
        ) {
          if (isBaseGameMode(setGameMode())) {
            const delay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SONG_030_11_BigWin_End].duration);
            delay.addOnComplete(() => {
              BgmControl.fadeInMelo(3000);
            });
            delay.start();
          } else {
            BgmControl.fadeInMelo(3000);
          }
        }
        client.writeQuery({
          query: getBalanceGql,
          data: {
            ...userBalance,
            balance: placeBet.balance.settled,
          },
        });
        saveReelPosition(placeBet.bet.outcomes[0]!.stateSnapshot.reelPosition);
      };
      SlotMachine.getInstance().setStopCallback(callBack.bind(this));
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsPopup(false);
    setIsOpenAutoplayPopup(false);
    setIsOpenInfoPopup(false);
    setIsOpenHistoryPopup(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      const spinState = SlotMachine.getInstance().state;

      if (!setIsAfterSpin()) {
        if (spinState !== SlotMachineState.IDLE || (setGameMode() === GameMode.BASE_GAME && !setBrokenGame())) {
          SlotMachine.getInstance().spin(isTurboSpin);
        }
      }

      if (spinState === SlotMachineState.IDLE && !setIsCollected() && !setCollectPush()) {
        setIsAfterSpin(true);
        if (setIsCollectSpin()) {
          eventManager.on(EventTypes.SET_IS_COLLECT_SPIN, onSpin);
          return;
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SONG_SFX_UI_Close });
        AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
        eventManager.emit(EventTypes.GAMBLE_CANCEL);
        eventManager.emit(EventTypes.DISABLE_COLLECT_BTN, true);
        eventManager.emit(EventTypes.DISABLE_GAMBLE_BTN, true);
        eventManager.on(EventTypes.AFTER_SPIN, onSpin);
        return;
      } else if (!setIsCollected() && setCollectPush()) {
        setIsAfterSpin(true);
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        eventManager.on(EventTypes.AFTER_SPIN, onSpin);
        return;
      } else {
        if (spinState === SlotMachineState.IDLE) eventManager.emit(EventTypes.BASE_GAME);
      }

      if ((spinState === SlotMachineState.IDLE && SlotMachine.getInstance().nextResultFlg) || setIsAfterSpin()) {
        if (isFreeSpinGameMode(setGameMode())) {
          return;
        }

        setCollectPush(false);
        eventManager.emit(EventTypes.SPIN_OK);

        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        resetPopupsStateToClosed();
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        SlotMachine.getInstance().clearNextResult();
        eventManager.emit(EventTypes.BASE_GAME);
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
            },
          },
        });
        if (setIsAutoSpins()) {
          setSpinWithAutoSpin(true);
        } else {
          setSpinWithAutoSpin(false);
        }
        if (setIsAfterSpin()) {
          setIsAfterSpin(false);
          eventManager.removeListener(EventTypes.AFTER_SPIN, onSpin);
        } else {
          setIsSpinInProgress(true);
          setIsSlotBusy(true);
          AudioApi.stop({ type: ISongs.SONG_SFX_UI_Close });
          AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
        }
        eventManager.removeListener(EventTypes.SET_IS_COLLECT_SPIN, onSpin);
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      eventManager.emit(EventTypes.FREE_SPIN_WATCH_OVER);
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (!setBrokenGame()) {
        SlotMachine.getInstance().clearNextResult();
      }

      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });

      // let spinState;
      // if (setBrokenGame()) {
      //   spinState = SlotMachineState.IDLE;
      // } else {
      //   spinState = SlotMachine.getInstance().state;
      //   SlotMachine.getInstance().spin(isTurboSpin);
      // }
      // if (spinState === SlotMachineState.IDLE) {
      //   client.writeQuery({
      //     query: isStoppedGql,
      //     data: {
      //       isSlotStopped: false,
      //     },
      //   });
      //   if (!setBrokenGame()) {
      //     SlotMachine.getInstance().clearNextResult();
      //   }
      //   fnGet({
      //     variables: {
      //       input: {
      //         slotId,
      //         coinAmount: setCoinAmount(),
      //         coinValue: setCoinValue(),
      //         lineSetId: lineSet.id,
      //         userBonusId: setCurrentBonus().id,
      //       },
      //     },
      //   });
      //   setIsSpinInProgress(true);
      //   setIsSlotBusy(true);
      // } else {
      //   client.writeQuery({
      //     query: isStoppedGql,
      //     data: {
      //       isSlotStopped: true,
      //     },
      //   });
      // }
    };

    const buyFeatureSpin = () => {
      setWinAmount(0);
      setLastRegularWinAmount(0);
      SlotMachine.getInstance().spin(isTurboSpin);
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SONG_SFX_UI_SpinStart });
    };

    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);

    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeatureSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      if (setIsCollectSpin()) eventManager.removeListener(EventTypes.AUTO_SPIN_COLLECT, checkAutoSpinSettings);

      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        setBalanceAmount() &&
        setStopOnBalanceIncrease() * setCoinValue() <= setBalanceAmount() - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        setBalanceAmount() &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - setBalanceAmount();

      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          eventManager.emit(EventTypes.GAMBLE_CHECK);
        }
      } else {
        eventManager.emit(EventTypes.GAMBLE_CHECK);
        onSpin(isTurboSpin);
      }
    } else {
      setIsCollectSpin(false);
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (setGameMode() === GameMode.BASE_GAME && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (setIsOpenInfoPopup() || setIsOpenHistoryPopup() || setIsOpenBetSettingsPopup() || setIsOpenAutoplayPopup())
          return;

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
            isCountUp: setIsCountUp(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isAutoPlay: false,
            isFadeOut: setIsFadeOut(),
            isBrokenBuyFeature: setBrokenBuyFeature(),
            isAfterSpin: setIsAfterSpin(),
            isReplay: setIsReplay(),
            isPhoenix: setIsPhoenix(),
          })
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        } else if (setIsCountUp()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
        } else if (setIsReplay()) {
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes.slice(2), (acc, item) => acc && item);
    const stop = _.reduce(winThreeTimes, (acc, item) => acc || item);
    if (play) {
      BgmControl.fadeInMelo(500);
    }

    if (!stop) {
      BgmControl.fadeOutMelo(3000);
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStatueIdle) {
      const timeOut = config.autoplay.timeOut;
      const spinState = SlotMachine.getInstance() != undefined ? SlotMachine.getInstance().state : 0;

      id = setTimeout(
        () => {
          const spinStateTimeOut = SlotMachine.getInstance() != undefined ? SlotMachine.getInstance().state : 0;

          if (
            setSpinWithAutoSpin() &&
            spinStateTimeOut === SlotMachineState.IDLE &&
            !setIsCollected() &&
            !setCollectPush()
          ) {
            if (!setIsCollectSpin()) eventManager.emit(EventTypes.GAMBLE_CANCEL);
            setIsCollectSpin(true);
            eventManager.on(EventTypes.AUTO_SPIN_COLLECT, checkAutoSpinSettings);
          } else {
            setIsCollectSpin(false);
            setSpinWithAutoSpin(false);
            if (spinStateTimeOut === SlotMachineState.IDLE) {
              checkAutoSpinSettings();
            }
          }
        },
        setAutoSpinsLeft() === setAutoSpinsAmount()
          ? 0
          : spinState === SlotMachineState.IDLE && !setIsCollected() && !setCollectPush()
          ? 0
          : timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStatueIdle]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
