import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import { debugDisplay } from '../../utils';
import { isMobileDevice } from '../../utils/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  FREESPINS_PANEL_MARGIN_X,
  FREESPINS_PANEL_MARGIN_Y,
  FREESPINS_PANEL_POS_X_LANDSCAPE,
  FREESPINS_PANEL_POS_X_PORTRAIT,
} from './config';
import { FreeSpinsCounter } from './freeSpinCounter';

export class FreeSpinsPanel extends ViewContainer {
  private spinCounter: FreeSpinsCounter;

  private isLandScape: boolean;

  protected bindApplicationResize = this.resize.bind(this);

  constructor(props: FreeSpinsTitleProps, isLandScape: boolean) {
    super();

    this.isLandScape = isLandScape;

    eventManager.addListener(EventTypes.RESIZE, this.bindApplicationResize);

    this.spinCounter = new FreeSpinsCounter(props);
    this.addChild(this.spinCounter);

    this.position.set(457, -110);

    let posX = 0;
    const posY = 0;
    [this.spinCounter].forEach((item) => {
      item.pivot.set(0, item.height / 2);
      item.position.set(posX, posY);
      posX += item.width + FREESPINS_PANEL_MARGIN_X;
    });

    if (this.isLandScape) {
      this.resize(2, 1);
    } else {
      this.resize(1, 2);
    }
  }

  public override destroy(): void {
    debugDisplay('FreeSpinsPanel destroy');
    eventManager.removeListener(EventTypes.RESIZE, this.bindApplicationResize);
  }

  private onResize(width: number, height: number): void {
    if (isMobileDevice() && width < height) {
      this.setPortraitLayout();
    } else {
      this.setLandscapeLayout();
    }
  }

  private setLandscapeLayout(): void {
    this.pivot.set(this.width / 2, this.height / 2);
    this.position.x = FREESPINS_PANEL_POS_X_LANDSCAPE;
  }

  private setPortraitLayout(): void {
    this.reset();

    const posX = this.width / 2;
    let posY = 0;

    [this.spinCounter].forEach((item) => {
      item.pivot.set(item.width / 2, 0);
      item.position.set(posX, posY);
      posY += item.height + FREESPINS_PANEL_MARGIN_Y;
    });

    this.pivot.set(this.width / 2, this.height);
    this.position.x = FREESPINS_PANEL_POS_X_PORTRAIT;
  }

  private reset(): void {
    this.children.forEach((i) => i.position.set(0, 0));
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      this.position.set(457, -110);
    } else {
      this.position.set(457, -225.5);
    }
  }
}
export default FreeSpinsPanel;
